import BG from "../images/BG.png";
import BG_Adult from "../images/BG Adults.png";
import BG_Family from "../images/BG family.png";
import BG_Kids from "../images/BG Kids.png";
import colourbg from "../images/colour-bg.png";
import ColourCaptor from "../images/ColourCaptor.png";
import Logo from "../images/Logo.png";
import BGHost from "../images/Host/BG.png";
import BGHost_Adult from "../images/Host/BG Adults.png";
import BGHost_Family from "../images/Host/BG family.png";
import BGHost_Kids from "../images/Host/BG Kids.png";
import ColourNumberBlob from "../images/Host/Colour Number Blob.png";
import UI_Fullscreen from "../images/Host/UI_Fullscreen.png";
import Ui_Help from "../images/Host/Ui_Help.png";
import UI_Volumeoff from "../images/Host/UI_Volume off.png";
import UI_Volumeon from "../images/Host/UI_Volume on.png";
import Black_Selected from "../images/Colours/Black_Selected.png";
import Black_Unselected from "../images/Colours/Black_Unselected.png";
import Blue from "../images/Colours/Blue.png";
import Brown from "../images/Colours/Brown.png";
import Green from "../images/Colours/Green.png";
import Grey from "../images/Colours/Grey.png";
import Orange from "../images/Colours/Orange.png";
import Pink from "../images/Colours/Pink.png";
import Purple from "../images/Colours/Purple.png";
import Red from "../images/Colours/Red.png";
import White_Selected from "../images/Colours/White_Selected.png";
import White_Unselected from "../images/Colours/White_Unselected.png";
import Yellow from "../images/Colours/Yellow.png";
import KidsIcon from "../images/Kids Icon.png";
import AdultsIcon from "../images/Adults Icon.png";
import FamilyIcon from "../images/Family Icon.png";
import KidsLogo from "../images/Junior logo.png";

const images = [
    BG,
    colourbg,
    ColourCaptor,
    Logo,
    BGHost,
    ColourNumberBlob,
    UI_Fullscreen,
    Ui_Help,
    UI_Volumeoff,
    UI_Volumeon,
    Black_Selected,
    Black_Unselected,
    Blue,
    Brown,
    Green,
    Grey,
    Orange,
    Pink,
    Purple,
    Red,
    White_Selected,
    White_Unselected,
    Yellow,
    KidsIcon,
    AdultsIcon,
    FamilyIcon,
    KidsLogo,
    BG_Kids,
    BG_Family,
    BG_Adult,
    BGHost_Adult,
    BGHost_Family,
    BGHost_Kids
];

const fonts = [
    { font: "Albus", path: `https://${window.location.host}/fonts/Gustav-Brun-Albus.otf` },
    { font: "Interstate", path: `https://${window.location.host}/fonts/Interstate-Regular.otf` },
];

export default {
    images,
    fonts,
};