/* eslint-disable default-case */
import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Player from "components/Player";
import Menu from "components/Menu";
import Tutorial from "components/Tutorial";
import DefaultStartMenuItems from 'components/DefaultStartMenuItems/DefaultStartMenuItems';

import getAvatarById from "constants/avatars";

import logo from "images/Logo.png";
import mutedIcon from "images/Host/UI_Volume off.png";
import unmutedIcon from "images/Host/UI_Volume on.png";
import fullscreenIcon from "images/Host/UI_Fullscreen.png";
import helpIcon from "images/Host/Ui_Help.png";
import blobImg from "images/Host/Colour Number Blob.png";

import Round1Gif from "animations/roundNumbers/Round-1.gif";
import Round2Gif from "animations/roundNumbers/Round-2.gif";
import Round3Gif from "animations/roundNumbers/Round-3.gif";
import Round4Gif from "animations/roundNumbers/Round-4.gif";
import Round5Gif from "animations/roundNumbers/Round-5.gif";
import Round6Gif from "animations/roundNumbers/Round-6.gif";
import Round7Gif from "animations/roundNumbers/Round-7.gif";
import Round8Gif from "animations/roundNumbers/Round-8.gif";

import BlackIcon from 'images/Colours/Black_Unselected.png';
import WhiteIcon from 'images/Colours/White_Unselected.png';
import BlueIcon from 'images/Colours/Blue.png';
import BrownIcon from 'images/Colours/Brown.png';
import GreenIcon from 'images/Colours/Green.png';
import GreyIcon from 'images/Colours/Grey.png';
import OrangeIcon from 'images/Colours/Orange.png';
import PinkIcon from 'images/Colours/Pink.png';
import PurpleIcon from 'images/Colours/Purple.png';
import RedIcon from 'images/Colours/Red.png';
import YellowIcon from 'images/Colours/Yellow.png';
import CaptureIcon from 'images/ColourCaptor.png';
import KidsLogo from 'images/Junior logo.png';

import cloudAnimFront from 'animations/Cloud-Front.js';
import cloudAnimBack from 'animations/Cloud-Back.js';
import rainbowAnim from 'animations/Rainbow.js';
import hourglassTimer from 'animations/HourglassTimer.js';
import colourSnatcherAnim from 'animations/ColourSnatcher.js';

import BGMusic from "audio/ColourBrainMusic.wav";
import ButtonClickSFX from "audio/ButtonClick.mp3";
import CoinSoundSFX from "audio/CoinSound.mp3";
import ColourSnatcherSFX from "audio/ColourSnatcher.wav";
import NewRoundSFX from "audio/NewRound.wav";
import AllWrongSFX from "audio/AllWrong.wav";
import PopBlackSFX from "audio/Pop_Black.wav";
import PopBlueSFX from "audio/Pop_Blue.wav";
import PopBrownSFX from "audio/Pop_Brown.wav";
import PopGreenSFX from "audio/Pop_Green.wav";
import PopGreySFX from "audio/Pop_Grey.wav";
import PopOrangeSFX from "audio/Pop_Orange.wav";
import PopPinkSFX from "audio/Pop_Pink.wav";
import PopPurpleSFX from "audio/Pop_Purple.wav";
import PopRedSFX from "audio/Pop_Red.wav";
import PopWhiteSFX from "audio/Pop_White.wav";
import PopYellowSFX from "audio/Pop_Yellow.wav";
import TitleSFX from "audio/Title.wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import Timer5SecondsStartSFX from "audio/timer-5-seconds-start.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import "animate.css";
import styles from 'components/HostStyles.module.scss';
import ErrorModal from './Utility/ErrorModal';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    ButtonClick: {
        import: ButtonClickSFX,
        loaded: null,
        volume:1,
        loop: false,
    },
    CoinSound: {
        import: CoinSoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ColourSnatcher: {
        import: ColourSnatcherSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRound: {
        import: NewRoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    AllWrong: {
        import: AllWrongSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBlack: {
        import: PopBlackSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBlue: {
        import: PopBlueSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBrown: {
        import: PopBrownSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopGreen: {
        import: PopGreenSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopGrey: {
        import: PopGreySFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopOrange: {
        import: PopOrangeSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopPink: {
        import: PopPinkSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopPurple: {
        import: PopPurpleSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopRed: {
        import: PopRedSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopWhite: {
        import: PopWhiteSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopYellow: {
        import: PopYellowSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Title: {
        import: TitleSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsStart: {
        import: Timer5SecondsStartSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Question: "question",
    ColourCapture: "colour_capture",
    Answers: "answers",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "colour_brain";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            reconnectionToken: "",

            contentFilter: 1,
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            players: [],
            //players: [
            //    {
            //        name: "SCOTT",
            //        id: "test-id-1",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-2",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-3",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-4",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-5",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-6",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-7",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-8",
            //        avatar: 2,
            //        showPlayer: true,
            //        colourData: {
            //            palette: [
            //                {
            //                    selected: false,
            //                    isActive: true,
            //                    colourName: "red"
            //                },
            //            ],
            //            score: 0,
            //        }
            //    }
            //],
            playersRequired: 3,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,

            skipTutorialCount: 0,
            timerText: 20,
            showTimer: false,
            stopTimerAnim: true,

            showStartButtons: true,
            showPlayAgainButtons: false,

            showRoundNumber: false,
            showRoundCounter: false,
            roundNumber: 0,
            maxRounds: 0,
             
            questionText: "",
            showQuestionSection: false,
            showRequiredColours: false,
            requiredColours: 0,
            revealColours: false,
            questionColours: [],
            revealedColours: [],
            allRevealed: false,

            doColourCapture: false,
            currentCaptor: { },
            currentCaptured: { },
            showCaptureText: false,
            movePos: {},

            showWinnerSection: false,
            showWinners: true,
            showRainbow: true,
            winners: [],
            gameEnded: false,
            doConfetti: false,

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
        };
        this.snatcherAnimRef = React.createRef();
        this.rainbowAnimRef = React.createRef();
        this.rainbowSegments = [120, 300];
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();

        this.doReconnect();

        this.toggleMute(true, false);
        this.initAudio();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    playColourPop(colourName) {
        if (colourName) {
            colourName = colourName.toLowerCase();
            let colourPop;
            switch (colourName) {
                case "black":
                    colourPop = audio.PopBlack;
                    break;
                case "white":
                    colourPop = audio.PopBlack;
                    break;
                case "blue":
                    colourPop = audio.PopBlack;
                    break;
                case "brown":
                    colourPop = audio.PopBlack;
                    break;
                case "green":
                    colourPop = audio.PopBlack;
                    break;
                case "grey":
                    colourPop = audio.PopBlack;
                    break;
                case "orange":
                    colourPop = audio.PopBlack;
                    break;
                case "pink":
                    colourPop = audio.PopBlack;
                    break;
                case "purple":
                    colourPop = audio.PopBlack;
                    break;
                case "red":
                    colourPop = audio.PopBlack;
                    break;
                case "yellow":
                    colourPop = audio.PopBlack;
                    break;
            }
            this.playAudio(colourPop);
        }
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted == true ? false : true;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayer.doCoinBounce = false;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = (confirm = false) => {
        let allConnected = true;
        for (let i = 0; i < this.state.players.length; i++) {
            let value = this.state.players[i].connected;
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.playAudio(audio.ButtonClick);
            this.setState({ showStartButtons: false, showStartWarning: false, });
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }
    goToLobby = () => {
        this.playAudio(audio.ButtonClick);
        this.state.room.send("change_game", {});
    }

    signalNewGame = (confirm = false) => {
        if (this.state.gameEnded) {
            let allConnected = true;
            for (let i = 0; i < this.state.players.length; i++) {
                let value = this.state.players[i].connected;
                if (value === false) allConnected = false;
            }

            if (!confirm && !allConnected) {
                this.setState({ showStartWarning: true });
            } else {
                this.playAudio(audio.ButtonClick);
                this.setState({ showWinnerSection: false, showStartWarning: false, });
                setTimeout(() => {
                    this.setState({ showPlayAgainButtons: false, gameEnded: false, showWinners: false, showPlayers: true, showRainbow: false, });
                    this.state.room.send("cb_new_game", {});
                }, 1500);
            }
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.signalNewGame(true);
    }

    confirmStartGame = () => {
        this.signalStartGame(true);
    }

    getArrayOfPlayers(players) {
        let arrPlayers = [];
        for (const [key, value] of Object.entries(players)) {
            arrPlayers.push(value);
        };
        return arrPlayers;
    }

    resetRoundVals() {
        let players = [...this.state.players];
        players = players.map((x) => {
            x.colourData.showColours = [];
            return x;
        });

        this.setState({ players, });

        this.setState({ showQuestionSection: false, });
        setTimeout(() => {
            this.setState({ revealColours: false, questionColours: [], showRequiredColours: false, revealedColours: [], allRevealed: false, });
        }, 2000);
    }

    getRequiredColoursText() {
        let colours = ["#FF5F6B", "#FBB03B", "#FFE62E", "#8CC63F", "#65C8D0", "#948BEA",];
        
        return <React.Fragment>
            <span style={{ color: "black", }}>{this.state.requiredColours} </span> 
            <span style={{ color: colours[0] }}>c</span>
            <span style={{ color: colours[1] }}>o</span>
            <span style={{ color: colours[2] }}>l</span>
            <span style={{ color: colours[3] }}>o</span>
            <span style={{ color: colours[4] }}>u</span>
            <span style={{ color: colours[5] }}>r</span>
            {
                this.state.requiredColours > 1 &&
                <span style={{ color: colours[0] }}>s</span>
            }
        </React.Fragment>
    }

    getColourByName(colourName, selected = false) {
        colourName = colourName.toLowerCase();
        switch (colourName) {
            case "black":
                return BlackIcon;
            case "white":
                return  WhiteIcon;
            case "blue":
                return BlueIcon;
            case "brown":
                return BrownIcon;
            case "green":
                return GreenIcon;
            case "grey":
                return GreyIcon;
            case "orange":
                return OrangeIcon;
            case "pink":
                return PinkIcon;
            case "purple":
                return PurpleIcon;
            case "red":
                return RedIcon;
            case "yellow":
                return YellowIcon;
        }
    }

    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.colourData.score - a.colourData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.colourData.score < arr[index - 1].colourData.score) lastIndex++;
                    return <tr className={`${styles.tableRow} ${styles.player}`}>
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.colourData.score}</div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </tr>
                })
            }
        </React.Fragment>
    }

    doRevealColours(message, callback = null) {
        this.setState({ revealColours: true, });
        let questionColours = [...this.state.questionColours];
        let revealedColours = [...this.state.revealedColours];
        let index = 0;
        const revealInterval = setInterval(() => {
            revealedColours.push(questionColours[index]);
            this.playColourPop(questionColours[index]);
            index++;
            this.setState({ revealedColours, });
            if (index == questionColours.length) {
                clearInterval(revealInterval);
                setTimeout(() => {
                    let players = [...this.state.players];
                    players.forEach((x, i) => {
                        if (x.colourData.isCorrect == false) {
                            players[i].colourData.showColours = [];
                        }
                    });
                    this.setState({ allRevealed: true, players });

                    setTimeout(() => {
                        players.forEach((x, i) => {
                            if (x.colourData.isCorrect) {
                                this.playAudio(audio.CoinSound);
                                players[i].doCoinBounce = true;
                            }
                        });
                        this.setState({ players });

                        setTimeout(() => {
                            if (callback != null) callback();
                        }, (message.pointsToAward || 0) * 950);
                    }, 1000);
                }, 2500);
            }
        }, 500);
    }

    getRoundCounters() {
        let roundCounters = [];
        for (let i = 1; i <= this.state.maxRounds; i++) {
            roundCounters.push(<div className={styles.counter}>
                <div className={`${styles.inner} ${this.state.roundNumber >= i && styles.show}`}></div>
            </div>);
        }
        return roundCounters;
    }
    getRoundGIF() {
        switch (this.state.roundNumber) {
            case 1:
                return Round1Gif;
            case 2:
                return Round2Gif;
            case 3:
                return Round3Gif;
            case 4:
                return Round4Gif;
            case 5:
                return Round5Gif;
            case 6:
                return Round6Gif;
            case 7:
                return Round7Gif;
            case 8:
                return Round8Gif;
        }
    }

    doRainbowLoop = () => {
        this.rainbowAnimRef.playSegments(this.rainbowSegments);
    }

    continueStartRound = () => {
        setTimeout(() => {
            this.setState({ showRoundNumber: false, });

            setTimeout(() => {
                this.setState({ showQuestionSection: true, });
                this.playAudio(audio.NewRound);

                setTimeout(() => {
                    this.setState({ showRequiredColours: true, });
                }, 250);

                setTimeout(() => {
                    this.setState({ showRoundCounter: false, });
                    this.state.room.send("cb_show_question", {});
                }, 1000);
            }, 2000);
        }, 6000);
    }

    showTimer() {
        this.setState({ showTimer: true, stopTimerAnim: false, });
    }

    hideTimer() {
        this.setState({ showTimer: false, });
        
        setTimeout(() => {
            this.setState({ stopTimerAnim: true, });
        }, 1500);
        if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
    }

    testFunction = () => {
        this.setState({ doColourCapture: !this.state.doColourCapture });
    }

    startColourCapture(message) {
        this.playAudio(audio.ColourSnatcher);
        this.setState({
            currentCaptor: message.captorPlayer,
            currentCaptured: message.capturedPlayer,
            doColourCapture: true,
            showTimer: false,
        });
    }

    frameEvent = (e) => {
        let frame = Math.round(e.currentTime);
        if (frame == 65) {
            this.setState({ showQuestionSection: false, showRequiredColours: false, });
        } else if (frame == 80) {
            this.setState({ showCaptureText: true, });
        } else if (frame == 240) {
            this.moveSnatcher();
        } else if (frame == 315) {
            let movePos = JSON.parse(JSON.stringify(this.state.movePos));
            movePos.rotate = 0;
            this.setState({ movePos, });
        }
        //else if (frame == 360) {
        //    this.setState({
        //        movePos: { top: 0, left: 0, rotate: 0, },
        //        doColourCapture: false,
        //        showTimer: true,
        //        showQuestionSection: true,
        //        showRequiredColours: true,
        //        showCaptureText: false,
        //    });
        //    this.state.room.send("capture_complete", {});
        //}
    }

    completeSnatcherAnim() {
        this.setState({
            movePos: { top: 0, left: 0, rotate: 0, },
            doColourCapture: false,
            showTimer: true,
            showQuestionSection: true,
            showRequiredColours: true,
            showCaptureText: false,
        });
        this.state.room.send("capture_complete", {});
    }

    moveSnatcher() {
        let movePos = {};

        movePos = this.getMovePosition();

        this.setState({ movePos, showCaptureText: false, });
    }

    getMovePosition() {
        let snatcher = document.getElementById(`snatcher`);
        let dest = document.getElementById(`player-${this.state.currentCaptured.id}`);

        if (snatcher && dest) {
            let snatcherPos = snatcher.getBoundingClientRect();
            let destPos = dest.getBoundingClientRect();

            let top, left, rotate, offset;

            offset = 30;

            top = destPos.top - snatcherPos.top;
            left = destPos.left - snatcherPos.left;
            top -= snatcherPos.height / 2;
            top += destPos.height / 2;

            if (left > 0) {
                rotate = 90;
                left -= snatcherPos.width / 2;
                left -= offset;
            } else {
                rotate = -90;
                left -= snatcherPos.width / 2;
                left += destPos.width;
                left += offset;
            }

            return { top, left, rotate, };
        } else {
            return { top: 0, left: 0, rotate: 0, };
        }
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {

            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    //changeContentFilter(value) {
    //    this.state.room.send("cb_toggle_content_filter", { value: value });
    //    console.log("changed content filter to: " + value);
    //}

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const roomId = this.getQueryStringValue("roomId");
            const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken});
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });

                        this.setState({ roomState: state, maxVoteCount: state.players.size });

                        if (state.colourData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, maxVoteCount: state.players.size, maxRounds: state.colourData.maxRounds });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.state.colourData.listen("contentFilter", (currentValue) => { this.setState({contentFilter: currentValue}) });

                    room.state.colourData.listen("gameState", (currentValue, previousValue) => {
                        if (currentValue != GameStates.Loading && currentValue != GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ gameState: currentValue });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);

                        player.onChange(() => {
                      
                        });
                        player.colourData.onChange(() => {
                            
                        });
                        player.listen("connected", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.connected = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        player.listen("votedSkip", (value) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            let statePlayer = { ...statePlayers[pos] };

                            statePlayer.votedSkip = value;

                            statePlayers[pos] = statePlayer;
                            this.setState({ players: statePlayers });
                        });
                        let changes = ["score", "answerCount", "ready", "isCorrect", "hasCapture", "isLeader"];
                        changes.forEach((change) => {
                            player.colourData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };
                                statePlayer.colourData[change] = value;
                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                                if (change == "score") {
                                    console.log("score change", statePlayer.colourData.score);
                                }
                            });
                        });
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, })
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.colourData.gameState);
                        if ((this.state.roomState.colourData.gameState == GameStates.Loading || this.state.roomState.colourData.gameState == GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });

                            room.send("cb_start_round", {});
                        }
                    }); 

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.setState({ questionText: message.question, requiredColours: message.requiredColours, showRoundCounter: true, });

                        setTimeout(() => {
                            this.playAudio(audio.Title);
                            this.setState({ roundNumber: message.roundNumber, showRoundNumber: true, });
                            
                        }, 1000);
                    }); 

                    room.onMessage("show_captured", (message) => {
                        console.log("show_captured", "received on", room.name, message);

                        this.startColourCapture(message);
                    });

                    room.onMessage("reveal_answers", (message) => {
                        console.log("reveal_answers", "received on", room.name, message);

                        this.hideTimer();
                        setTimeout(() => {
                            let index = 0;
                            const revealInterval = setInterval(() => {
                                let players = [...this.state.players];
                                let player = { ...players[index] };
                                let count = 0;
                                player.colourData.showColours = [];
                                player.doCoinBounce = false;
                                let palette = player.colourData.palette.filter((x) => x.selected && x.isActive);

                                for (let j = 0; j < this.state.requiredColours; j++) {
                                    player.colourData.showColours.push(true);
                                    if (palette[j]) this.playColourPop(palette[j].colourName);
                                    players[index] = player;
                                }
                                this.setState({ players, });
                                index++;

                                if (index >= players.length) {
                                    setTimeout(() => {
                                        this.state.room.send("cb_finished_reveal", {});
                                    }, 1500);
                                    clearInterval(revealInterval);
                                }
                            }, 350);
                        }, 1000);
                    }); 

                    room.onMessage("round_results", (message) => {
                        console.log("round_results", "received on", room.name, message);

                        this.setState({ questionColours: message.answers, showRequiredColours: false, });
                        setTimeout(() => {
                            this.doRevealColours(message, () => {
                                setTimeout(() => {
                                    this.resetRoundVals();
                                    setTimeout(() => {
                                        this.state.room.send("cb_start_round", {});
                                    }, 1000);
                                }, 2000);
                            });
                        }, 1000);
                    });

                    room.onMessage("round_no_correct", (message) => {
                        console.log("round_no_correct", "received on", room.name, message);

                        this.setState({ questionColours: message.answers, showRequiredColours: false, });
                        setTimeout(() => {
                            this.doRevealColours(message, () => {
                                setTimeout(() => {
                                    this.resetRoundVals();
                                    setTimeout(() => {
                                        this.state.room.send("cb_start_round", {});
                                    }, 1000);
                                }, 2000);
                            });
                        }, 1000);
                    }); 

                    room.onMessage("round_all_correct", (message) => {
                        console.log("round_all_correct", "received on", room.name, message);

                        this.setState({ questionColours: message.answers, showRequiredColours: false, });
                        setTimeout(() => {
                            this.doRevealColours(message, () => {
                                setTimeout(() => {
                                    this.resetRoundVals();
                                    setTimeout(() => {
                                        this.state.room.send("cb_start_round", {});
                                    }, 1000);
                                }, 2000);
                            });
                        }, 1000);
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.setState({ winners: message.winners, showPlayers: false, showWinnerSection: true,  });
                        this.playAudio(audio.DrumRoll);
                        setTimeout(() => {
                            this.setState({ showWinners: true, doConfetti: true, });
                            setTimeout(() => {
                                this.setState({ showRainbow: true, });
                            }, 1000);
                            setTimeout(() => {
                                this.state.room.send("reached_end", {});
                                this.setState({ showPlayAgainButtons: true, gameEnded: true, });
                            }, 2000);
                        }, 2000);
                    }); 

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, gameEnded: false, showWinners: false, showPlayers: true, showRainbow: false, showWinnerSection: false, });
                        setTimeout(() => {
                            this.resetRoundVals();
                            setTimeout(() => {
                                this.state.room.send("cb_start_round", {});
                            }, 1000);
                        }, 5000);
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);

                        this.showTimer();
                        if (message.count == 5) {
                            //this.playAudio(audio.Timer5SecondsStart);
                            this.playAudio(audio.Timer5SecondsClock);
                        }
                        if (message.count <= 0) {
                            setTimeout(() => {
                                this.hideTimer();
                            }, 1000);
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}/lobby/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Colour Brain.";
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}/` });
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={`${styles.gameContainer} ${this.state.contentFilter === 0 ? styles.kids : this.state.contentFilter === 1 ? styles.family : styles.adult}`}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={`${styles.logo} ${styles.show}`}/>
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players}/>
                                }
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartButtons ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                <div style={{ zIndex: 20,}}>
                                    {
                                    this.state.doConfetti &&
                                    <Confetti
                                        width={window.innerWidth}
                                        height={window.innerHeight}
                                        initialVelocityY={20}
                                        numberOfPieces={500}
                                        recycle={false}
                                        confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}
                                        initialVelocityY={{ min: -10, max: -30, }}
                                        initialVelocityX={{ min: -10, max: 10, }}
                                        onConfettiComplete={() => this.setState({ doConfetti: false, })}
                                    />
                                    }
                                </div>
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="helpIcon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo} ${this.state.contentFilter !== 0 && styles.show}`} alt="logo" />
                                    <img src={KidsLogo} className={`${styles.logo} ${styles.kids} ${this.state.contentFilter === 0 && styles.show}`} alt="kidslogo" />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <DefaultStartMenuItems
                                        room={this.state.room}
                                        bigScreen={true}
                                        contentFilter={this.state.contentFilter}
                                        contentFilterMessageName={"cb_toggle_content_filter"}
                                        onClickStartGame={() => this.signalStartGame()}
                                        onClickGoToLobby={this.goToLobby}
                                        onToggleSkipTutorial={this.toggleSkipTutorial}
                                    />

                                    //this.state.showStartButtons &&
                                    //<div className={styles.startButtonSection}>
                                    //    <div className={styles.skipBox}>
                                    //        <input className={styles.checkbox} type="radio" id="adults" name="filter" onClick={() => this.changeContentFilter(0)} checked={this.state.contentFilter===0} />
                                    //        <label for="adults">Adults</label>
                                    //        <input className={styles.checkbox} type="radio" id="both" name="filter" onChange={() => this.changeContentFilter(1)} checked={this.state.contentFilter === 1} />
                                    //        <label for="both">Both</label>
                                    //        <input className={styles.checkbox} type="radio" id="kids" name="filter" onChange={() => this.changeContentFilter(2)} checked={this.state.contentFilter === 2} />
                                    //        <label for="kids">Kids</label>
                                    //    </div>
                                    //    <button className={`${styles.mainButton}`} onClick={() => this.signalStartGame()}>start game</button>
                                    //    <button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>go to lobby</button>
                                    //    <div className={styles.skipBox}>
                                    //        <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                    //        <label for="checkbox">skip tutorial</label>
                                    //    </div>
                                    //</div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 0) {
                                                return <React.Fragment>
                                                        <div className={`${styles.playerLine} ${x.showPlayer && styles.showLine}`}></div>
                                                    <Player player={x} left={true} revealedColours={this.state.revealedColours} allRevealed={this.state.allRevealed} />
                                                </React.Fragment>
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 != 0) {
                                                return <React.Fragment>
                                                        <div className={`${styles.playerLine} ${x.showPlayer && styles.showLine}`}></div>
                                                    <Player player={x} left={false} revealedColours={this.state.revealedColours} allRevealed={this.state.allRevealed} />
                                                </React.Fragment>
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.roundCounterSection} ${this.state.showRoundCounter && styles.show}`}>
                                    {/*<div className={styles.text}>{this.state.roundNumber}/{this.state.maxRounds}</div>*/}
                                    <div className={styles.counterSection}>
                                        {
                                            this.getRoundCounters()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <div className={styles.clock}>
                                        <Lottie
                                            options={hourglassTimer}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                            isPaused={!this.state.showTimer}
                                            isStopped={this.state.stopTimerAnim}
                                            speed={0.55}
                                        />
                                    </div>
                                    <div key={this.state.timerText} className={`${styles.timerText} ${this.state.timerText <= 5 && styles.animate}`}>{this.state.timerText}</div>
                                </div>
                                <div className={`${styles.questionSection} ${this.state.showQuestionSection && styles.show}`}>
                                    <div className={styles.questionText}>{this.state.questionText}</div>
                                    <div className={`${styles.coloursRow} ${this.state.revealColours && styles.showColours}`}>
                                        {
                                            this.state.questionColours.map((x, index) => {
                                                return <div className={`${styles.colour} ${this.state.revealedColours.includes(x) && styles.showColour}`}>
                                                    <img src={this.getColourByName(x)} className={styles.colourImg} />
                                                    <div className={styles.colourText}>{x}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={`${styles.numberText} ${this.state.showRequiredColours && styles.showNumbers}`}>
                                        <img className={styles.blob} src={blobImg} />
                                        <div className={styles.text}>{this.getRequiredColoursText()}</div>
                                    </div>
                                </div>
                                <div className={`${styles.roundNumber} ${this.state.showRoundNumber && styles.show}`}>
                                    {
                                        this.state.showRoundNumber &&
                                        <img onLoad={this.continueStartRound} src={this.getRoundGIF()} className={styles.number} />
                                    }
                                </div>
                                <div className={styles.colourCaptureSection}>
                                    <div id="snatcher" className={styles.snatcherAnim} style={{ transform: `translate(${this.state.movePos.left}px, ${this.state.movePos.top}px) rotate(${this.state.movePos.rotate}deg)`, }}>
                                        <Lottie
                                            options={colourSnatcherAnim}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                            isStopped={!this.state.doColourCapture}
                                            eventListeners={[
                                                {
                                                    eventName: 'enterFrame',
                                                    callback: (e) => this.frameEvent(e)
                                                },
                                                {
                                                    eventName: 'complete',
                                                    callback: () => this.completeSnatcherAnim(),
                                                },
                                            ]}
                                            ref={animation => {
                                                this.snatcherAnimRef = animation;
                                            }}
                                        />
                                    </div>
                                    <div className={`${styles.textBox} ${this.state.showCaptureText && styles.show}`}>
                                        {this.state.currentCaptor.name}<br /><span className={styles.smaller}>summoned the</span><br />Colour Snatcher
                                    </div>
                                </div>
                                <div className={`${styles.endGameSection} ${this.state.showWinnerSection && styles.showSection}`}>
                                    <div className={`${styles.winnersSection} ${this.state.showWinners && styles.show}`}>
                                        <div className={`${styles.rainbow}`}>
                                            {
                                                this.state.showRainbow &&
                                                <Lottie
                                                    options={rainbowAnim}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                    eventListeners={[
                                                        {
                                                            eventName: 'DOMLoaded',
                                                            callback: () => this.doRainbowLoop()
                                                        },
                                                    ]}
                                                    segments={this.rainbowSegments}
                                                    ref={animation => {
                                                        this.rainbowAnimRef = animation;
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div className={`${styles.cloudBox} ${styles.one}`}>
                                            <div className={`${styles.cloud} ${styles.back}`}>
                                                <Lottie
                                                    options={cloudAnimBack}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                            <div className={styles.playerGroup}>
                                                {
                                                    this.state.winners.map((x, index, arr) => {
                                                        if (index % 2 == 0) {
                                                            return <div className={styles.winner}>
                                                                <Lottie
                                                                    options={getAvatarById(x.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className={`${styles.cloud} ${styles.front}`}>
                                                <Lottie
                                                    options={cloudAnimFront}
                                                    width="100%"
                                                    height="100%"
                                                    isClickToPauseDisabled={true}
                                                />
                                            </div>
                                        </div>
                                            <div className={`${styles.cloudBox} ${styles.two}`}>
                                                <div className={`${styles.cloud} ${styles.back}`}>
                                                    <Lottie
                                                        options={cloudAnimBack}
                                                        width="100%"
                                                        height="100%"
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>
                                                <div className={styles.playerGroup}>
                                                    {
                                                        this.state.winners.map((x, index, arr) => {
                                                            if (index % 2 != 0) {
                                                                return <div className={styles.winner}>
                                                                    <Lottie
                                                                        options={getAvatarById(x.avatar).idleAnim}
                                                                        width="100%"
                                                                        height="100%"
                                                                        isClickToPauseDisabled={true}
                                                                    />
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className={`${styles.cloud} ${styles.front}`}>
                                                    <Lottie
                                                        options={cloudAnimFront}
                                                        width="100%"
                                                        height="100%"
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                    <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <div className={`${styles.tableRow} ${styles.header}`}>
                                            <div className={styles.position}>position</div>
                                            <div className={styles.name}>player</div>
                                            <div className={styles.score}>score</div>
                                        </div>
                                        {
                                            this.getSortedPlayersByScore()
                                        }
                                    </div>
                                    <div className={`${styles.buttonsSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <button className={`${styles.button}`} onClick={() => this.signalNewGame()}>play again</button>
                                        <button className={`${styles.button}`} onClick={this.goToLobby}>change game</button>
                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}