import React, { useRef, useState } from 'react';
import styles from "components/DefaultStartMenuItems/DefaultStartMenuItems.module.scss";
import kidsIcon from "images/Kids Icon.png"
import familyIcon from "images/Family Icon.png"
import adultsIcon from "images/Adults Icon.png"

export default function DefaultStartMenuItems(props) {

    if (!props.contentFilter) console.log("contentFilter prop is not defined");

    function changeContentFilter(value){
        if (!props.contentFilterMessageName) {
            console.log("contentFilterMessageName prop is not defined");
            return;
        }
        props.room.send(props.contentFilterMessageName, { value: value });
        const userFriendlyValues = ["Adult", "Both", "Kids"]
        console.log("changed content filter to: " + userFriendlyValues[value]);
    }

    return (
        <div className={props.bigScreen ? styles.bigButtons : styles.buttons}>
            {
                props.contentFilterMessageName &&
                <div className={styles.contentFilter}>
                    <input type="range" min="0" max="2" value={props.contentFilter} className={styles.bar} id="filter" step="1" onChange={(e) => changeContentFilter(Number(e.target.value))}/>
                    <div className={ styles.icons}>
                        <img src={kidsIcon} alt="kidsIcon" onClick={() => changeContentFilter(0)} />
                        <img src={familyIcon} alt="familyIcon" onClick={() => changeContentFilter(1)} />
                        <img src={adultsIcon} alt="adultsIcon" onClick={() => changeContentFilter(2)} />
                    </div>
                </div>
            }
            <div className={styles.button} onClick={() => props.onClickStartGame()}>start game</div>
            <div className={`${styles.button} ${styles.smaller}`} onClick={() => props.onClickGoToLobby()}>go to lobby</div>
            <div className={styles.skipBox}>
                <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={props.onToggleSkipTutorial} />
                <label for="checkbox">skip tutorial</label>
            </div>
        </div>
    )
}