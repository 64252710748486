import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';

import Player from "components/Player";

import getAvatarById from "constants/avatars";

import logo from "images/Logo.png";
import mutedIcon from "images/Host/UI_Volume off.png";
import unmutedIcon from "images/Host/UI_Volume on.png";
import fullscreenIcon from "images/Host/UI_Fullscreen.png";
import helpIcon from "images/Host/Ui_Help.png";
import blobImg from "images/Host/Colour Number Blob.png";

import BlackIcon from 'images/Colours/Black_Unselected.png';
import WhiteIcon from 'images/Colours/White_Unselected.png';
import BlueIcon from 'images/Colours/Blue.png';
import BrownIcon from 'images/Colours/Brown.png';
import GreenIcon from 'images/Colours/Green.png';
import GreyIcon from 'images/Colours/Grey.png';
import OrangeIcon from 'images/Colours/Orange.png';
import PinkIcon from 'images/Colours/Pink.png';
import PurpleIcon from 'images/Colours/Purple.png';
import RedIcon from 'images/Colours/Red.png';
import YellowIcon from 'images/Colours/Yellow.png';
import CaptureIcon from 'images/ColourCaptor.png';

import PhoneSelectingCapture from 'animations/tutorial/Phone_Selecting Capture.js';
import PhoneSelectingColours from 'animations/tutorial/Phone_Selecting Colours.js';
import PhoneSnatchingCards from 'animations/tutorial/Phone_Snatching Cards.js';

import BGMusic from "audio/ColourBrainMusic.wav";
import ButtonClickSFX from "audio/ButtonClick.mp3";
import CoinSoundSFX from "audio/CoinSound.mp3";
import ColourSnatcherSFX from "audio/ColourSnatcher.wav";
import NewRoundSFX from "audio/NewRound.wav";
import AllWrongSFX from "audio/AllWrong.wav";
import PopBlackSFX from "audio/Pop_Black.wav";
import PopBlueSFX from "audio/Pop_Blue.wav";
import PopBrownSFX from "audio/Pop_Brown.wav";
import PopGreenSFX from "audio/Pop_Green.wav";
import PopGreySFX from "audio/Pop_Grey.wav";
import PopOrangeSFX from "audio/Pop_Orange.wav";
import PopPinkSFX from "audio/Pop_Pink.wav";
import PopPurpleSFX from "audio/Pop_Purple.wav";
import PopRedSFX from "audio/Pop_Red.wav";
import PopWhiteSFX from "audio/Pop_White.wav";
import PopYellowSFX from "audio/Pop_Yellow.wav";
import TitleSFX from "audio/Title.wav";
import Timer5SecondsClockSFX from "audio/timer-5-seconds-clock.mp3";
import Timer5SecondsStartSFX from "audio/timer-5-seconds-start.mp3";
import DrumRollSFX from "audio/drum-roll.wav";

import "animate.css";
import styles from 'components/TutorialStyles.module.scss';


var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    ButtonClick: {
        import: ButtonClickSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    CoinSound: {
        import: CoinSoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ColourSnatcher: {
        import: ColourSnatcherSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRound: {
        import: NewRoundSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    AllWrong: {
        import: AllWrongSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBlack: {
        import: PopBlackSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBlue: {
        import: PopBlueSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopBrown: {
        import: PopBrownSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopGreen: {
        import: PopGreenSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopGrey: {
        import: PopGreySFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopOrange: {
        import: PopOrangeSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopPink: {
        import: PopPinkSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopPurple: {
        import: PopPurpleSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopRed: {
        import: PopRedSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopWhite: {
        import: PopWhiteSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PopYellow: {
        import: PopYellowSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Title: {
        import: TitleSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Timer5SecondsStart: {
        import: Timer5SecondsStartSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    Timer5SecondsClock: {
        import: Timer5SecondsClockSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DrumRoll: {
        import: DrumRollSFX,
        loaded: null,
        volume: 1,
        loop: false,
    }
};


export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            colours: [
                {
                    id: 0,
                    name: "red",
                    hexCode: "#FE0000",
                },
                {
                    id: 1,
                    name: "blue",
                    hexCode: "#0090FF",
                },
                {
                    id: 2,
                    name: "yellow",
                    hexCode: "#FCFF00",
                },
                {
                    id: 3,
                    name: "green",
                    hexCode: "#23FF00",
                },
                {
                    id: 4,
                    name: "purple",
                    hexCode: "#AE00FF",
                },
                {
                    id: 5,
                    name: "pink",
                    hexCode: "#FFAFCC",
                },
                {
                    id: 6,
                    name: "orange",
                    hexCode: "#FF9700",
                },
                {
                    id: 7,
                    name: "grey",
                    hexCode: "#AEAEAE",
                },
                {
                    id: 8,
                    name: "brown",
                    hexCode: "#9F3600",
                },
                {
                    id: 9,
                    name: "black",
                    hexCode: "#000000",
                },
                {
                    id: 10,
                    name: "white",
                    hexCode: "#FFFFFF",
                },
            ],
            showStepOne: true,
            showStepOneText: false,
            showStepOneColours: [false, false, false, false, false, false, false, false, false, false, false,],

            showStepTwo: true,
            showStepTwoText: false,
            showSampleQuestion: false,
            showRequiredColours: false,

            showStepThree: true,
            showStepThreeText: false,
            showStepThreePhone: false,
            playStepThreePhoneAnim: false,

            showStepFour: true,
            showStepFourText: false,
            showSamplePlayers: false,
            samplePlayersLeft: [
                {
                    name: "",
                    id: "left-1",
                    avatar: 3,
                    showPlayer: true,
                    colourData: {
                        palette: [
                            {
                                selected: true,
                                isActive: true,
                                colourName: "black"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "yellow"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "white"
                            },
                        ],
                        score: 0,
                        showColours: [true, true, true,]
                    }
                },
                {
                    name: "",
                    id: "left-2",
                    avatar: 6,
                    showPlayer: true,
                    colourData: {
                        palette: [
                            {
                                selected: true,
                                isActive: true,
                                colourName: "black"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "yellow"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "red"
                            },
                        ],
                        score: 0,
                        showColours: [true, true, true,]
                    }
                }
            ],
            samplePlayersRight: [
                {
                    name: "",
                    id: "right-1",
                    avatar: 8,
                    showPlayer: true,
                    colourData: {
                        palette: [
                            {
                                selected: true,
                                isActive: true,
                                colourName: "blue"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "yellow"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "brown"
                            },
                        ],
                        score: 0,
                        showColours: [true, true, true,]
                    }
                },
                {
                    name: "",
                    id: "right-2",
                    avatar: 1,
                    showPlayer: true,
                    colourData: {
                        palette: [
                            {
                                selected: true,
                                isActive: true,
                                colourName: "black"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "yellow"
                            },
                            {
                                selected: true,
                                isActive: true,
                                colourName: "white"
                            },
                        ],
                        score: 0,
                        showColours: [true, true, true,]
                    }
                }
            ],
            correctColours: [
                {
                    id: 9,
                    name: "black",
                    hexCode: "#000000",
                },
                {
                    id: 2,
                    name: "yellow",
                    hexCode: "#FCFF00",
                },
                {
                    id: 10,
                    name: "white",
                    hexCode: "#FFFFFF",
                },
            ],
            showStepFourColours: [false, false, false,],

            showStepFive: true,
            showStepFiveText: false,
            stepFiveText: `Want to stop\nsomeone in the lead?`,
            showStepFivePhones: false,
            playStepFivePhoneAnimOne: false,
            playStepFivePhoneAnimTwo: false,

            showStepSix: true,
            showStepSixText: false,
        };
        this.snatcherAnimRef = React.createRef();

    }

    componentDidMount() {
        this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        this.preloadAudio();
        Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    playColourPop(colourName) {
        if (colourName) {
            colourName = colourName.toLowerCase();
            let colourPop;
            switch (colourName) {
                case "black":
                    colourPop = audio.PopBlack;
                    break;
                case "white":
                    colourPop = audio.PopBlack;
                    break;
                case "blue":
                    colourPop = audio.PopBlack;
                    break;
                case "brown":
                    colourPop = audio.PopBlack;
                    break;
                case "green":
                    colourPop = audio.PopBlack;
                    break;
                case "grey":
                    colourPop = audio.PopBlack;
                    break;
                case "orange":
                    colourPop = audio.PopBlack;
                    break;
                case "pink":
                    colourPop = audio.PopBlack;
                    break;
                case "purple":
                    colourPop = audio.PopBlack;
                    break;
                case "red":
                    colourPop = audio.PopBlack;
                    break;
                case "yellow":
                    colourPop = audio.PopBlack;
                    break;
            }
            this.playAudio(colourPop);
        }
    }


    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }


    getColourByName(colourName, selected = false) {
        colourName = colourName.toLowerCase();
        switch (colourName) {
            case "black":
                return BlackIcon;
            case "white":
                return WhiteIcon;
            case "blue":
                return BlueIcon;
            case "brown":
                return BrownIcon;
            case "green":
                return GreenIcon;
            case "grey":
                return GreyIcon;
            case "orange":
                return OrangeIcon;
            case "pink":
                return PinkIcon;
            case "purple":
                return PurpleIcon;
            case "red":
                return RedIcon;
            case "yellow":
                return YellowIcon;
        }
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    doStepOne() {
        this.setState({ showStepOne: true, showStepOneText: true, });
        setTimeout(() => {
            let colourTime = 0;
            this.state.colours.forEach((x, i) => {
                setTimeout(() => {
                    let showStepOneColours = [...this.state.showStepOneColours];
                    showStepOneColours[i] = true;
                    this.playColourPop(x.name);
                    this.setState({ showStepOneColours });
                    colourTime += (i * 150);
                }, i * 150);
            });

            setTimeout(() => {
                this.setState({ showStepOne: false, });
                setTimeout(() => {
                    this.doStepTwo();
                }, 2000);
            }, (4000 + colourTime));
        }, 1000);
    }

    doStepTwo() {
        this.setState({ showStepTwo: true, });
        setTimeout(() => {
            this.setState({ showStepTwoText: true });
            setTimeout(() => {
                this.setState({ showSampleQuestion: true });
                setTimeout(() => {
                    this.setState({ showRequiredColours: true });

                    setTimeout(() => {
                        this.setState({ showStepTwo: false });
                        this.doStepThree();
                    }, 4000);
                }, 1000);
            }, 1000);
        }, 1000);
    }

    doStepThree() {
        this.setState({ showStepThree: true, });
        setTimeout(() => {
            this.setState({ showStepThreePhone: true, showStepThreeText: true, });
            setTimeout(() => {
                this.setState({ playStepThreePhoneAnim: true, });

                setTimeout(() => {
                    this.setState({ showStepThree: false, });
                    this.doStepFour();
                }, 5000)
            }, 1500);
        }, 1000);
    }

    doStepFour() {
        this.setState({ showStepFour: true, });
        setTimeout(() => {
            this.setState({ showStepFourText: true, });
            setTimeout(() => {
                this.setState({ showSamplePlayers: true, });
                setTimeout(() => {
                    let colourTime = 0;
                    this.state.correctColours.forEach((x, i) => {
                        setTimeout(() => {
                            let showStepFourColours = [...this.state.showStepFourColours];
                            showStepFourColours[i] = true;
                            this.playColourPop(x.name);
                            this.setState({ showStepFourColours });
                            colourTime += (i * 150);
                        }, i * 150);
                    });
                    setTimeout(() => {
                        let samplePlayersLeft = [...this.state.samplePlayersLeft];
                        let samplePlayersRight = [...this.state.samplePlayersRight];
                        samplePlayersLeft[0].colourData.score += 1;
                        samplePlayersRight[1].colourData.score += 1;

                        this.setState({ samplePlayersLeft, samplePlayersRight });
                        setTimeout(() => {
                            this.setState({ showSamplePlayers: false });
                            setTimeout(() => {
                                this.setState({ showStepFour: false, });
                                this.doStepFive();
                            }, 1000);
                        }, 2000);
                    }, (2000 + colourTime));
                }, 2000);
            }, 2000);
        }, 1000);
    }

    doStepFive() {
        this.setState({ showStepFive: true, });
        setTimeout(() => {
            this.setState({ showStepFiveText: true, });
            setTimeout(() => {
                this.setState({ showStepFivePhones: true, });
                setTimeout(() => {
                    this.setState({ playStepFivePhoneAnimOne: true });

                    setTimeout(() => {
                        this.setState({ playStepFivePhoneAnimTwo: true, });

                        setTimeout(() => {
                            this.setState({ showStepFiveText: false, });
                            setTimeout(() => {
                                this.setState({ showStepFiveText: true, stepFiveText: `Use the Colour Capture\nto steal their colours\nfor the next round!` });
                                setTimeout(() => {
                                    this.setState({ showStepFivePhones: false });
                                    setTimeout(() => {
                                        this.setState({ showStepFive: false, });
                                        this.doStepSix();
                                    }, 1000);
                                }, 5500);
                            }, 1000);
                        }, 4000);
                    }, 2000);
                }, 1500);
            }, 1500);
        }, 1000);
    }

    doStepSix() {
        this.setState({ showStepSix: true, });
        setTimeout(() => {
            this.setState({ showStepSixText: true });
            setTimeout(() => {
                this.setState({ showStepSix: false });
                this.props.room.send("end_tutorial", {});
            }, 3000);
        }, 1000);
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneText && styles.show}`}>This is a party game with a twist...<br />...All the answers are colours</div>
                    <div className={styles.colourList}>
                        {
                            this.state.colours.map((x, i) => {
                                return <React.Fragment>
                                    {
                                        i == 5 &&
                                        <div className={styles.break} />
                                    }
                                    <div className={`${styles.colour} ${this.state.showStepOneColours[i] && styles.show}`}>
                                        <img src={this.getColourByName(x.name)} className={styles.colourBlob} />
                                    </div>
                                </React.Fragment>
                            })
                        }
                    </div>
                </div>
                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepTwoText && styles.show}`}>Each round you'll be given a question:</div>
                    <div className={`${styles.questionBox}`}>
                        <div className={`${styles.questionText} ${this.state.showSampleQuestion && styles.show}`}>What colour are the taxis in London, New York & Rome?</div>
                        <div className={`${styles.numberText} ${this.state.showRequiredColours && styles.show}`}>
                            <img className={styles.blob} src={blobImg} />
                            <div className={styles.blobText}>
                                <span style={{ color: "white", }}>3 </span>
                                <span style={{ color: "#FF5F6B" }}>c</span>
                                <span style={{ color: "#FBB03B" }}>o</span>
                                <span style={{ color: "#FFE62E" }}>l</span>
                                <span style={{ color: "#8CC63F" }}>o</span>
                                <span style={{ color: "#65C8D0" }}>u</span>
                                <span style={{ color: "#948BEA" }}>r</span>
                                <span style={{ color: "#FF5F6B" }}>s</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={styles.rowBox}>
                        <div className={`${styles.text} ${this.state.showStepThreeText && styles.show}`}>Select the colour<br />on your device.</div>
                        <div className={`${styles.phoneAnimBox} ${this.state.showStepThreePhone && styles.show}`}>
                            <Lottie
                                options={PhoneSelectingColours}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                                isPaused={!this.state.playStepThreePhoneAnim}
                                isStopped={!this.state.playStepThreePhoneAnim}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => {
                                            this.setState({ playStepThreePhoneAnim: false });
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFourText && styles.show}`}>If you get it right,<br />you'll score points...</div>
                    <div className={styles.colourList}>
                        {
                            this.state.correctColours.map((x, i) => {
                                return <div className={`${styles.colour} ${this.state.showStepFourColours[i] && styles.show}`}>
                                    <img src={this.getColourByName(x.name)} className={styles.colourBlob} />
                                </div>
                            })
                        }
                    </div>
                    <div className={styles.playerColBox}>
                        <div className={`${styles.playerColumn} ${styles.left} ${this.state.showSamplePlayers && styles.show}`}>
                            {
                                this.state.samplePlayersLeft.map((x, index, arr) => {
                                    return <React.Fragment>
                                        {
                                            index > 0 &&
                                            <div className={`${styles.playerLine} ${x.showPlayer && styles.showLine}`}></div>
                                        }
                                        <Player player={x} left={true} revealedColours={this.state.revealedColours} allRevealed={this.state.allRevealed} />
                                    </React.Fragment>
                                })
                            }
                        </div>
                        <div className={`${styles.playerColumn} ${styles.right} ${this.state.showSamplePlayers && styles.show}`}>
                            {
                                this.state.samplePlayersRight.map((x, index, arr) => {
                                    return <React.Fragment>
                                        {
                                            index > 0 &&
                                            <div className={`${styles.playerLine} ${x.showPlayer && styles.showLine}`}></div>
                                        }
                                        <Player player={x} left={false} revealedColours={this.state.revealedColours} allRevealed={this.state.allRevealed} />
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`}>
                    <div className={styles.rowBox}>
                        <div className={`${styles.phoneAnimBox} ${this.state.showStepFivePhones && styles.show}`}>
                            <Lottie
                                options={PhoneSelectingCapture}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                                isPaused={!this.state.playStepFivePhoneAnimOne}
                                isStopped={!this.state.playStepFivePhoneAnimOne}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => {
                                            this.setState({ playStepFivePhoneAnimOne: false });
                                        }
                                    },
                                ]}
                            />
                        </div>
                        <div className={`${styles.text} ${this.state.showStepFiveText && styles.show}`} style={{width: "35%"}}>{this.state.stepFiveText}</div>
                        <div className={`${styles.phoneAnimBox} ${this.state.showStepFivePhones && styles.show}`}>
                            <Lottie
                                options={PhoneSnatchingCards}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                                isPaused={!this.state.playStepFivePhoneAnimTwo}
                                isStopped={!this.state.playStepFivePhoneAnimTwo}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => {
                                            this.setState({ playStepFivePhoneAnimTwo: false });
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${styles.stepSix} ${styles.step} ${this.state.showStepSix && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepSixText && styles.show}`}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}