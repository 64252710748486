import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";

import BlackIcon from 'images/Colours/Black_Unselected.png';
import WhiteIcon from 'images/Colours/White_Unselected.png';
import BlueIcon from 'images/Colours/Blue.png';
import BrownIcon from 'images/Colours/Brown.png';
import GreenIcon from 'images/Colours/Green.png';
import GreyIcon from 'images/Colours/Grey.png';
import OrangeIcon from 'images/Colours/Orange.png';
import PinkIcon from 'images/Colours/Pink.png';
import PurpleIcon from 'images/Colours/Purple.png';
import RedIcon from 'images/Colours/Red.png';
import YellowIcon from 'images/Colours/Yellow.png';
import CaptureIcon from 'images/ColourCaptor.png';

import CoinJumpAnim from 'animations/CoinJump.js';
import { Button } from 'reactstrap';


export default class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedColours: [],
            playCoin: false,
            visualScore: props.player.colourData.score,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.player.colourData.score === 0) {
        //if (nextProps.player.colourData.score === 0 && this.props.player.colourData.score !== 0) {
            this.setState({ visualScore: 0 });
        }
    }

    bounceComplete = () => {
        this.setState({ visualScore: this.state.visualScore += 1, });
    }

    getColourByName(colourName, selected = false) {
        colourName = colourName.toLowerCase();
        switch (colourName) {
            case "black":
                return BlackIcon;
            case "white":
                return WhiteIcon;
            case "blue":
                return BlueIcon;
            case "brown":
                return BrownIcon;
            case "green":
                return GreenIcon;
            case "grey":
                return GreyIcon;
            case "orange":
                return OrangeIcon;
            case "pink":
                return PinkIcon;
            case "purple":
                return PurpleIcon;
            case "red":
                return RedIcon;
            case "yellow":
                return YellowIcon;
        }
    }

    getFilteredPalette() {
        let palette = this.props.player.colourData.palette.filter((x) => x.selected && x.isActive);

        let colourPalette = palette.map((x, index) => {
            return <div className={`${styles.colour} ${this.props.player.colourData.showColours && this.props.player.colourData.showColours[index] && styles.show} `}>
                <img src={this.getColourByName(x.colourName)} className={styles.innerColour} />
            </div>
        })

        return colourPalette;
    }


    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.left ? "" : styles.reverse} ${this.props.player.showPlayer && !this.props.player.spectating && styles.show}`}>
                <div id={`potato-${this.props.player.id}`} className={`${styles.potato}`}>
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <div className={`${styles.textSection}`}>
                    <div className={`${styles.name}`}>{this.props.player.name}</div>
                    <div className={`${styles.colourList} ${this.props.left ? "" : styles.reverse}`}>
                        {
                            this.getFilteredPalette()
                        }
                        <div className={`${styles.coinSection}`}>
                            <Lottie
                                options={CoinJumpAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                                isStopped={this.props.player.doCoinBounce == false || this.state.visualScore >= this.props.player.colourData.score}
                                eventListeners={[
                                    {
                                        eventName: 'loopComplete',
                                        callback: () => this.bounceComplete(),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className={`${styles.points}`}>score: {this.state.visualScore}</div>
                </div>
            </div>
        )
    }
}